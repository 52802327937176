<script>
export default {
  name: 'nav',
  computed: {
    sidebarItems() {
      return [
        {
          _name: 'CSidebarNav',
          _children: [
            {
              _name: 'CSidebarNavItem',
              name: 'Dashboard',
              to: '/dashboard',
              icon: 'cil-speedometer',
              badge: {
                color: 'primary',
                text: 'NEW',
              },
            },

            {
              _name: 'CSidebarNavItem',
              name: 'Users',
              to: '/users',
              icon: 'cil-user',
            },

            {
              _name: 'CSidebarNavItem',
              name: 'Credit User',
              to: '/credit-user',
              icon: 'cil-user',
            },
            {
              _name: 'CSidebarNavDropdown',
              name: 'Reports',
              icon: 'cil-envelope-open',
              items: [
                {
                  name: 'Purchase History',
                  to: '/reports/purchase-history',
                },
                {
                  name: 'Unit Usage History',
                  to: '/reports/units-usage',
                },
              ],
            },

            {
              _name: 'CSidebarNavDropdown',
              name: 'Sender Names',
              icon: 'cil-puzzle',
              items: [
                {
                  name: 'All',
                  to: '/sender-names/all',
                },
                {
                  name: 'Pending',
                  to: '/sender-names/pending',
                },
                {
                  name: 'Approved',
                  to: '/sender-names/approved',
                },
                {
                  name: 'Denied',
                  to: '/sender-names/denied',
                },
              ],
            },

            {
              _name: 'CSidebarNavItem',
              name: 'Outbox',
              to: '/outbox',
              icon: 'cil-envelope-open',
            },
          ],
        },
      ];
    },
  },
};
</script>
