<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="(value) => setAction(['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img
        src="../assets/images/ziga-white.png"
        style="height: 6.5vh"
        alt="zigatext-logo"
        class="c-sidebar-brand-minimized"
      />

      <img
        src="../assets/images/zigatext-white.png"
        style="height: 6.5vh"
        alt="zigatext-logo"
        class="c-sidebar-brand-full"
      />
    </CSidebarBrand>
    <CRenderFunction flat :contentToRender="sidebarItems" />
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="toggleAction('sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import SidebarItems from './SidebarItems';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'TheSidebar',
  extends: SidebarItems,
  computed: {
    ...mapState('coreuiStore', ['sidebarMinimize', 'sidebarShow']),
    show() {
      return this.sidebarShow;
    },
    minimize() {
      return this.sidebarMinimize;
    },
  },
  methods: {
    ...mapActions('coreuiStore', ['setAction', 'toggleAction']),
  },
};
</script>

<style scoped>
.background {
  background-color: 'rgb(60,75,100)';
}
</style>
