<template>
  <div class="c-app" :class="{ 'c-dark-theme': darkMode }">
    <TheSidebar />
    <TheAside />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';

import TheSidebar from './TheSidebar';
import TheHeader from './TheHeader';
import TheFooter from './TheFooter';
import TheAside from './TheAside';

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheAside,
  },
  created() {
    this.handleLogout();
  },
  computed: {
    ...mapState('coreuiStore', ['darkMode']),
  },
  methods: {
    ...mapActions('auth', ['logout']),

    handleLogout() {
      const logoutTime = new Date(
        StorageService.getItem('logoutTime')
      ).getTime();
      const currentTime = new Date().getTime();
      if (currentTime > logoutTime) {
        this.logout();
        document.body.classList.remove(
          'aside-menu-lg-show',
          'header-fixed',
          'sidebar-fixed'
        );

        window.location.reload();
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
